export const GET_ORDER = 'GET_ORDER'

export const orderFieldMap = {
  orders_products_id: 'Product',
  products_quantity: 'Product Quantity',
  sold_price: 'Price',
  final_price: 'Cost',
  sold_price_before_override: 'Sold Price Before Override',
  products_name: 'Product Name',
  orders_id: 'Products', // For orders id updates on product move, orders_products.orders_id
}
