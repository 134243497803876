import React from 'react'

export interface IRwTableHead {
  className?: string
  stickyTop?: boolean
  [x: string]: any
}

const RwTableHead: React.FC<IRwTableHead> = ({
  children,
  className = '',
  stickyTop = true,
  ...rest
}) => {
  const stickyTopClass = stickyTop ? 'sticky-top' : ''

  return (
    <thead 
    style={{transform: 'translateY(-1px)'}} 
    className={`${stickyTopClass} ${className}`} 
    {...rest}
    >
      {children}
    </thead>
  )
}

export default RwTableHead
