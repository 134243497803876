import React from 'react'
import { ListGroup, OverlayTrigger } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'

interface IRowContextMenu {
  [x: string]: any
  rootClose?: boolean
  isModal?: boolean
  disabled?: boolean
  hideOnSelect?: boolean
}

const RowContextMenu: React.FC<IRowContextMenu> = ({
  children,
  rootClose = false,
  isModal = false,
  disabled = false,
  hideOnSelect = false,
}) => {
  const [show, setShow] = React.useState(false)
  const handleHide = () => {
    if (hideOnSelect) setShow(false)
  }

  const renderMenu = (props: any) => (
    <div
      {...props}
      className={`modal-overlay border rounded ms-1 me-3`}
      onClick={handleHide}
    >
      <ListGroup>{children}</ListGroup>
    </div>
  )
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="left-start"
        overlay={disabled ? <></> : renderMenu}
        rootClose={rootClose}
        show={show}
        onToggle={setShow}
      >
        <div className="px-2">
          <BsThreeDotsVertical className="fs-4" />
        </div>
      </OverlayTrigger>
    </>
  )
}

export default RowContextMenu
